import { translate } from "@ollie-sports/i18n";
import { OrgCouponType, OrgCouponUseType } from "@ollie-sports/models";

export const PRETTY_ORG_COUPON_TYPE: (locale: string) => Record<OrgCouponType, string> = locale => ({
  [OrgCouponType.amountOff]: translate.common.AmountOff,
  [OrgCouponType.percentOff]: translate.common.PercentOff
});

export const PRETTY_ORG_COUPON_USE_TYPE: (locale: string) => Record<OrgCouponUseType, string> = locale => ({
  [OrgCouponUseType.all]: translate.common.All,
  [OrgCouponUseType.invoices]: translate.common.Invoices,
  [OrgCouponUseType.registration]: translate.common.Registration,
  [OrgCouponUseType.tryouts]: translate.common.Tryouts
});
