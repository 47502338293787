import { translate } from "@ollie-sports/i18n";
import { OrgPaymentType, OrgRegistrationStatus, OverallInvoiceStatus } from "@ollie-sports/models";

export const PRETTY__ORG_PAYMENT_TYPE: (locale: string) => Record<OrgPaymentType, string> = locale => ({
  [OrgPaymentType.invoiceCredit]: translate.common.Credit,
  [OrgPaymentType.invoiceFailedPayment]: translate.common.FailedPayment,
  [OrgPaymentType.invoiceFailedECheckPayment]: translate.common.FailedPayment,
  [OrgPaymentType.invoiceDefault]: translate.common.Payment,
  [OrgPaymentType.openOrgEventDefault]: `${translate.common.Tryouts}/${translate.common.Camps}`
});
