import { defineMessage } from "@ollie-sports/i18n";
import {
  __ConversationTeam,
  __ConversationOrg,
  __ConversationAccount,
  AccountId,
  PlayerBundleId,
  TeamId,
  Account,
  PrettyPlayerBundle,
  Team,
  OrgRegistration,
  OrgRegistrationStatus,
  OrgSeason,
  OrgInvoice,
  OrgPayment,
  OrgRegistrationAnswer,
  OrgCoupon,
  OrgSeasonId,
  OrgRegistrationPackage,
  OrgId,
  OrgRegistrationPaymentStatus,
  OrgInvoice__Manual,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgInvoice__Registration,
  OrgInvoice__RegistrationPaymentPlanInstallment,
  OrgRegistrationPackageId
} from "@ollie-sports/models";

export const NONE = "___NONE___" as const;

//On the conversation list, we leave out the `accounts` property b/c it's too heavy and is only needed for backend stuff like populating the title
//We also always populate derivedTitle to minimize fetching on the frontend. It's potentially a ton of accounts
export type ConversationSimple = (
  | Omit<__ConversationTeam, "title">
  | Omit<__ConversationOrg, "title">
  | Omit<__ConversationAccount, "accounts" | "title">
) & {
  derivedTitle: string;
  lastMessageText?: string;
  //Field will ONLY be set the conversation is a direct message to one other person
  oneOnOneAccountIconInfo?: { type: "photo" | "initials"; value: string };
  //Only set if type account
  numAccountIds?: number;
};

export type PlayerBundleRegistrationData = {
  playerBundleId: string;
  orgId: string;
  prioritizedRegistrationPackageId: string | undefined;
  status: OrgRegistrationStatus;
  orgRegistration: OrgRegistration | undefined;
  orgSeasonId: OrgSeasonId;
  allMatchingPackagesWithRankings?: Record<OrgRegistrationPackageId, number>;
};

export type OrgMemberTableData = {
  accountOrPlayerBundleId: AccountId | PlayerBundleId;
  firstName: string;
  lastName: string;
  type: "account" | "player_bundle";
  teamIds: TeamId[];
  teamNames: string[];
  isPlayer: boolean;
  isGuardian: boolean;
  isStaff: boolean;
  isHeadCoach: boolean;
  isAssistantCoach: boolean;
  isStaffMember: boolean;
  isTeamAdmin: boolean;
  email?: string;
  phoneNumber?: string;
};

export type OrgMemberDetailsPlayerBundleMetadataSimple = {
  prettyPlayerBundle: PrettyPlayerBundle;
  registrationData?: PlayerBundleRegistrationData[];
};
export type OrgMemberDetailsPlayerBundleMetadata = OrgMemberDetailsPlayerBundleMetadataSimple & {
  orgInvoices: OrgInvoice[];
  orgPayments: OrgPayment[];
  orgCoupons: OrgCoupon[];
};

type OrgMemberDetailsDataBase = {};

export type OrgMemberDetailsDataAccount = OrgMemberDetailsDataBase & {
  type: "account";
  account: Account;
  managedPrettyPlayerBundlesWithMetadata: OrgMemberDetailsPlayerBundleMetadata[];
};
export type OrgMemberDetailsDataPlayerBundle = OrgMemberDetailsDataBase & {
  type: "playerBundle";
  prettyPlayerBundleWithMetadata: OrgMemberDetailsPlayerBundleMetadata & {
    orgRegistrationAnswers?: OrgRegistrationAnswer[];
  };
  familyMemberPrettyPlayerBundlesWithMetadata: OrgMemberDetailsPlayerBundleMetadataSimple[];
};

export type OrgMemberDetailsData = OrgMemberDetailsDataAccount | OrgMemberDetailsDataPlayerBundle;

export const importantOrgEmailNotificationText = defineMessage({
  defaultMessage: "{orgName} has sent you an important email communication. Please check your email."
});

export const importantNonOrgEmailNotificationText = defineMessage({
  defaultMessage: "{userName} has sent you an important email communication. Please check your email."
});

export type OrgRegistrationInfo = {
  orgSeason: OrgSeason;
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
  prioritizedRegistrationPackage: OrgRegistrationPackage;
  status:
    | OrgRegistrationStatus.bad
    | OrgRegistrationStatus.registered
    | OrgRegistrationStatus.unregistered
    | OrgRegistrationStatus.incomplete;
  paymentStatus: OrgRegistrationPaymentStatus;
  invoicePaymentMSDates: number[];
  pendingAutoChargeMSDates: number[]; //Only has auto charge dates that HAVE NOT been paid yet
  orgRegistration: OrgRegistration | undefined;
};

export type OrgInvoiceGroupStatus = "notStarted" | "notStartedPastDue" | "inProgress" | "inProgressPastDue" | "completed";
export type OrgInvoiceGroup =
  | {
      type: "manual";
      status: OrgInvoiceGroupStatus;
      parent: OrgInvoice__Manual;
      children: OrgInvoice__ManualPaymentPlanInstallment[];
    }
  | {
      type: "registration";
      status: OrgInvoiceGroupStatus;
      parent: OrgInvoice__Registration;
      children: OrgInvoice__RegistrationPaymentPlanInstallment[];
    };
